.nav {
  background: #3a9147;
  height: 7vh;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem calc((100vw - 1000px) / 2);
  z-index: 12;
}

.navMenu {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
  white-space: nowrap;
}
