body {
  /* overflow: hidden; */
  background-color: #366863;
  -ms-overflow-style: none;
}

.invisibleM {
  /*same color as bg */
  color: greenyellow;
}

.homepage {
  width: 100%;
  position: absolute;
}

.lemon {
  color: yellow;
  font-size: 0.8vw;
}

.green {
  color: green;
}

.title {
  width: 40vw;
  margin-top: auto;
  margin-bottom: auto;
  color: aliceblue;
  font-size: 0.9vw;
}

div.titleRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.copyrightArea {
  position: absolute;
  right: 0;
  color: #2e5753;
}

.pizzaGuyPicture {
  height: 50vh;
  width: auto;
}
