.karutaPage {
  /* background-image: url("https://res.cloudinary.com/jerrick/image/upload/v1531856341/p7ykgzou3hjxhvepevcq.jpg"); */
  background-image: url("../../Themes/backgroundImages/smile.jpg");
  background-color: rgb(255, 152, 0);
  height: 100vh; /* 100vh is size of screen */
  width: 100%;
  position: absolute;
}

/* @media all and (min-width: 1800px) { */
div.mainInput {
  margin: auto;
  padding: 1.5vh;
  margin-bottom: 10px;
  width: min-content;
  min-width: 65vw;

  /*background-color: orange;
  /* border-radius: 35px; */
}

div.flexbox {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
}

div.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

div.reverseRow {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
}

.header {
  /* background-color: aquamarine; */
  margin-right: 10px;
  font-size: 17pt;
  font-style: italic;
}

.headerText {
  font-size: 42pt;
  font-style: normal;
  color: rgb(230, 15, 201);
}

div.shakeBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 25vw;
  justify-content: flex-end;
}

div.presetChoice {
  justify-content: flex-start;
  /*background-color: aquamarine;
  /* border-radius: 35px; */
  margin-top: 1.2vh;
  font-size: 14pt;
  min-width: 250px;
  display: flex;
  flex-direction: column;
}

.presetTitle {
  font-size: 16pt;
  font-weight: 500;
}

.disclaimerText {
  font-size: 13pt;
  font-style: italic;
}

.evaluateButton {
  margin-right: 3vw;
  justify-self: flex-end;
}

.copyButton {
  margin-top: 0.1vw;
  margin-right: 3.75vw;
}

.cardsOutput {
  border-radius: 20px;
  padding: 10px;
  /* background-color: #fff; */
  background-color: rgb(246, 232, 199);
  border: rgb(164, 31, 50) solid;
  border-width: 2.5px;
  color: rgb(92, 95, 148); /* I think #055d67 may look better 5 93 103*/
  width: 61vw;
  margin-left: 0.8vw;
}

.outputTitle {
  font-size: 16pt;
  font-weight: 400;
  color: #dbf2f1;

  margin-left: 1.2vw;
  margin-top: 10px;
  margin-bottom: 10px;
}

.joe {
  /* mama. fu */
  color: #dbf2f1;
}

.toggleTextSize {
  font-size: 20pt;
}

.additionalDescription {
  font-size: 14pt;
  color: rgb(153, 2, 2);
}
