.App {
  text-align: center;
  height: auto;
}

::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

html {
  scrollbar-width: none;
}
