.page {
  max-width: 72vw;
  min-width: 45vw;
  /* min-height: 45vw; */
  width: min-content;

  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5vw;
  margin-bottom: 3.5vw;
  padding-bottom: 1vw;

  border-radius: 45px;
  background-color: rgb(44, 10, 12);
  border: rgb(37, 0, 0) solid;
  border-width: 0.35vw;
  color: rgb(181, 26, 33);
}
